import React, { ReactElement } from "react";
import MyFlex from "../MyFlex";
import { Header } from "../MyHeader";
import { Outlet } from "react-router-dom";

const styles = {
  wrap: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    gap: "var(--gap)",
    minHeight: "100vh",
    paddingBottom: "var(--paddingSmall)",
  },
};

export default function (): ReactElement {
  return (
    <div style={styles.wrap}>
      <Header />

      <main className="container">
        <MyFlex vertical height="100%">
          <Outlet />
        </MyFlex>
      </main>
    </div>
  );
}
