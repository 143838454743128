import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
//@ts-ignore
import store from "./store";
import "./index.css";
import App from "./App";
import {
  createNoContentBlock,
  createProposeToInstall,
  createText,
  createWrapBtns,
  createButton,
  getEl,
} from "./helpers/elements";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "./helpers/localstorage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
if ("serviceWorker" in navigator)
  window.addEventListener("load", async () => {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      if (!registration)
        await navigator.serviceWorker.register("/service-worker.js", {
          scope: "/",
        });
      else registration.update();
    } catch (err) {
      console.log("service-worker failed:", err);
    }
  });
const updateOnlineStatus = () => {
  const headerBlock = getEl("isHeader");
  const existingEl = getEl("noConnectionBlock");
  if (!navigator.onLine) {
    if (headerBlock && !existingEl) {
      const noConnectBlock = createNoContentBlock();
      headerBlock.prepend(noConnectBlock);
    }
  } else {
    if (existingEl) existingEl.remove();
  }
};
let deferredPrompt: any = null;
const promptInstall = () => {
  if (deferredPrompt) deferredPrompt.prompt();
};
const promptClose = () => {
  const el = getEl("proposeToInstall");
  if (el) el.remove();
  setLocalStorageData("promptinstall", String(false));
};
const showInstallButton = () => {
  const rootBlock = getEl("root");
  if (rootBlock) {
    const proposeToInstall = createProposeToInstall();
    const text = createText();
    const wrapBtns = createWrapBtns();
    const btnToInstall = createButton(true);
    const btnToCancel = createButton(false);
    proposeToInstall.append(text);
    btnToInstall.addEventListener("click", promptInstall);
    btnToCancel.addEventListener("click", promptClose);
    wrapBtns.append(btnToInstall);
    wrapBtns.append(btnToCancel);
    proposeToInstall.append(wrapBtns);
    rootBlock.append(proposeToInstall);
  }
};
window.addEventListener("online", updateOnlineStatus);
window.addEventListener("offline", updateOnlineStatus);
window.addEventListener("beforeinstallprompt", (e) => {
  if (deferredPrompt) return;
  const status = getLocalStorageData("promptinstall");
  if (status === "false") return;
  deferredPrompt = e;
  showInstallButton();
});
if (!getLocalStorageData("mode")) setLocalStorageData("mode", "grid");
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
