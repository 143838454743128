import React, { ReactElement, ChangeEvent, useState } from "react";
import { sanitize, maskCost } from "../../helpers";
import { maskCarNumber, maskPhoneNumber } from "../../helpers";
import MyDivide from "../MyDivide";

interface IProps {
  value: string;
  onChange: (value: string) => void;
  title?: string;
  select?: boolean;
  carNumber?: boolean;
  phoneNumber?: boolean;
  cost?: boolean;
  milleage?: boolean;
  placeholder?: string;
}

function MyInput({
  title = "",
  select = false,
  carNumber = false,
  phoneNumber = false,
  cost = false,
  milleage = false,
  placeholder = "",
  value,
  onChange,
}: IProps): ReactElement {
  const [isHovered, setIsHovered] = useState(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = sanitize(e.target.value);
    if (carNumber) onChange(maskCarNumber(val));
    else if (phoneNumber) onChange(maskPhoneNumber(val));
    else if (cost || milleage) onChange(maskCost(val));
    else onChange(val);
  };
  const style = {
    wrap: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    input: {
      cursor: select ? "pointer" : "",
      width: "100%",
      backgroundColor: "var(--white)",
      appearance: "none",
      border: "var(--border)",
      borderColor: isHovered ? "var(--primary)" : "var(--gray)",
      padding: "var(--paddingSmall) var(--paddingBase)",
      borderRadius: "var(--borderRadius)",
    },
  };
  return (
    <div
      // @ts-ignore
      style={style.wrap}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {title && <MyDivide title={title} left hovered={isHovered} />}

      <input
        // @ts-ignore
        style={style.input}
        readOnly={select}
        value={value}
        onChange={handleChange}
        placeholder={placeholder || title}
      />
    </div>
  );
}

export default MyInput;
