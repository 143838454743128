import React, { useState, ReactElement } from "react";
import MyButton from "../MyButton";
import { LogoutOutlined } from "@ant-design/icons";
import {
  clearLocalstorage,
  getStatusLocalStorageData,
} from "../../helpers/localstorage";
import { clearCookies, getStatusCookie } from "../../helpers/cookie";
import DownloadBtn from "../Controls/DownloadBtn";

const styles = {
  navbar: {
    width: "max-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "var(--gap)",
  },
};

export default function (): ReactElement {
  const [isAuth, setIsAuth] = useState<boolean>(
    getStatusLocalStorageData() || getStatusCookie(),
  );
  const logout = () => {
    clearLocalstorage();
    clearCookies();
    setIsAuth(false);
    window.location.href = "/auth";
  };
  return isAuth ? (
    <nav style={{ ...styles.navbar }}>
      <DownloadBtn title="Скачать БД" />

      <MyButton
        click={logout}
        alt="Выйти из аккаунта"
        icon={<LogoutOutlined />}
        red
      />
    </nav>
  ) : (
    <div />
  );
}
