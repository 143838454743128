import React, { memo, ReactElement } from "react";
import Navbar from "../Navbar";
import Logo from "../MyLogo";
import MyFlex from "../MyFlex";

const styles = {
  wrap: {
    width: "100%",
    backgroundColor: "var(--white)",
    top: 0,
    zIndex: 1,
  },
  header: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    padding: "var(--paddingBase) 0",
    display: "flex",
    gap: "var(--gap)",
  },
};
export const Header = (): ReactElement => {
  return (
    <div style={{ position: "sticky", ...styles.wrap }}>
      <header
        style={{ ...styles.header, flexDirection: "column" }}
        id="isHeader"
      >
        <div className="container">
          <MyFlex between>
            <Logo />

            <Navbar />
          </MyFlex>
        </div>
      </header>
    </div>
  );
};
