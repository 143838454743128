import React, {
  useState,
  useMemo,
  useCallback,
  ReactElement,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { myFetch } from "../helpers/my_fetch";
import { Modal, Col, Row } from "antd";
import MyFlex from "../components/MyFlex";
import CreateCar from "../components/Forms/Create";
import TheCard from "../components/MyCard";
import MySpiner from "../components/MySpiner";
import { PlusCircleFilled } from "@ant-design/icons";
import { showErrorNotify } from "../helpers/notify";
import ModeControls from "../components/Controls";
import { IState } from "../types/store";
import { IRes } from "../types/index";
import MyTable from "../components/MyTable";
import MyButton from "../components/MyButton";

export default function (): ReactElement {
  const navigate = useNavigate();
  const mode = useSelector((state: IState) => state.view);
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [modalKey, setModalKey] = useState(Date.now());
  const getData = useCallback(async (): Promise<void> => {
    setLoader(true);
    try {
      const { data, error }: IRes = await myFetch("", {}, "GET", 1);
      if (error) {
        setList(data);
        return showErrorNotify(error);
      }
      setList(data);
    } catch ({ message }: any) {
      setList([]);
    } finally {
      setLoader(false);
    }
  }, []);
  const isGrid = useMemo(() => mode === "grid", [mode]);
  useEffect(() => {
    getData();
  }, []);
  const toClose = useCallback(() => {
    setIsModal(false);
    setModalKey(Date.now());
  }, []);
  const toInsidePage = useCallback(
    (obj: any) => navigate(`/inside/${obj?.id}`),
    [],
  );
  if (loader)
    return (
      <MyFlex center height="100%">
        <MySpiner big />
      </MyFlex>
    );
  return (
    <MyFlex vertical>
      <MyFlex between>
        <MyButton
          click={() => setIsModal(true)}
          icon={<PlusCircleFilled />}
          alt="Создать объект"
          green
        />

        <ModeControls />
      </MyFlex>

      {!isGrid && <MyTable list={list} toInsidePage={toInsidePage} />}

      {isGrid && (
        <Row gutter={[8, 8]}>
          {list &&
            list.map((object: any) => {
              return (
                <Col xs={12} sm={8} lg={6} xl={4} key={object?.id}>
                  <TheCard object={object} />
                </Col>
              );
            })}
        </Row>
      )}

      <Modal open={isModal} onCancel={toClose}>
        <CreateCar getData={getData} toClose={toClose} key={modalKey} />
      </Modal>
    </MyFlex>
  );
}
