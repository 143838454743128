import React, { ReactElement } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import ErrorPage from "./pages/ErrorPage";
import AuthPage from "./pages/Auth";
import ListPage from "./pages/index";
import InsidePage from "./pages/InsidePage";
//@ts-ignore
import { useWindowSize } from "./store/useWindowSize.js";

export default function (): ReactElement {
  useWindowSize();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ListPage />} />

          <Route path="inside/:id" element={<InsidePage />} />

          <Route path="auth" element={<AuthPage />} />

          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
