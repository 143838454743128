import { getCookie } from "./cookie";
import { getLocalStorageData } from "./localstorage";
import { IHeader, IParams } from "../types/my_fetch";
/*
 * Функция создания заголовков для запроса на сервер
 */
export const createHeaders = (isForm: boolean, page: number = 1): IHeader => {
  const h: any = {};
  if (!isForm) h["Content-Type"] = "application/json";
  const idFromLocalstorage = getLocalStorageData("id");
  const idFromCookie = getCookie("id");
  h.datestart = new Date().getTime();
  h.page = page;
  h.userid = idFromCookie || idFromLocalstorage || "auth";
  return h;
};
/*
 * Функция создания параметров для запроса на сервер
 */
export const createParams = (
  method: string,
  body: any,
  headers: any,
): IParams => {
  if (method === "GET") return { method, headers };
  return { method, body, headers };
};
/*
 * Функция запроса на сервер
 */
export const myFetch = async (
  url: any,
  b: any = {},
  m: any = "GET",
  p: any = 1,
  archive: boolean = false,
): Promise<any> => {
  const path = "http://localhost:3000/api";
  //   const path = 'https://www.resalecrm.ru/api';
  const h: IHeader = createHeaders(false, p);
  const data: string = JSON.stringify(b);
  const params: object = createParams(m, data, h);
  return archive
    ? await fetch(`${path}/${url}`, params)
    : (await fetch(`${path}/${url}`, params)).json();
};
/*
 * Функция отправки файлов на сервер
 */
export const uploadFiles = async (
  form: any,
  objectid: string,
  itemid?: string,
  main?: Boolean | undefined,
): Promise<any> => {
  const h: any = {};
  const p: any = createParams("POST", form, h);
  h.userid = getCookie("id") || getLocalStorageData("id");
  h.objectid = objectid;
  h.itemid = itemid;
  h.main = main;
  const path = `${process.env.REACT_APP_BASE_URL}/file/add`;
  return (await fetch(path, p)).json();
};
/*
 * Функция удаления файла на сервере
 */
export const delFile = async (
  namefile: string, // Имя файла
  objectid: string, // Идентификатор объекта
  itemid?: string, // Необязательный идентификатор элемента
): Promise<any> => {
  // Формируем заголовки запроса с данными пользователя
  const h = {
    userid: getCookie("id") || getLocalStorageData("id"),
    namefile,
    objectid,
    itemid,
  };
  // Создаем параметры запроса с методом "POST" и заголовками
  const p: any = createParams("POST", h, h);
  // URL для запроса к API на удаление файла
  const path = `${process.env.REACT_APP_BASE_URL}/file/del`;
  // Выполняем запрос к API и возвращаем ответ в формате JSON
  return (await fetch(path, p)).json();
};
/*
 * Функция запроса архива с сервера
 */
export const fetchBD = async (objectId: String | undefined): Promise<any> => {
  // URL для запроса к API на удаление файла
  const path = `${process.env.REACT_APP_BASE_URL}/bd/bd`;
  // Заголовки запроса
  const h: any = {};
  h.userid = getCookie("id") || getLocalStorageData("id");
  h["Content-Type"] = "application/json";
  h.datestart = new Date().getTime();
  if (objectId) h.objectId = objectId;
  // Создаем параметры
  const p: any = createParams("GET", h, h);
  // Выполняем запрос
  return await fetch(path, p);
};
